import React, { useEffect, useState } from "react";
import blueSkullAnimated from "./assets/blueSkullAnimated.gif";
import blueSkull1 from "./assets/blueSkull1.png";
import BlueSkullSmurfCat from "./assets/blueSkullSmurfCat.jpeg";

const BlueFlame = () => {
  const [skulls, setSkulls] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (skulls.length >= 40) {
        setSkulls([]);
      }

      setSkulls((oldSkulls) => [
        ...oldSkulls,
        {
          id: Date.now(),
          top: `${Math.floor(Math.random() * 100 - 40)}vh`,
          left: `${Math.floor(Math.random() * 100)}vw`,
          animation: ["spin", "jump", "pulse"][Math.floor(Math.random() * 3)],
        },
      ]);
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [skulls]);

  return (
    <div className="flex flex-col w-full md:h-screen h-full justify-center items-center bg-[#040B15] relative overflow-hidden">
      <div className="fixed font-extrabold top-0 left-0 w-full bg-blue-500 text-white text-center py-2 z-[200] flex flex-col items-center">
        $СИНИЙ ЧЕРЕП{" "}
        <span className="font-lg animate-pulse mx-10">
          0xA63b884da8EcB83566e4069853495D6aE9b11A76
        </span>{" "}
        <div className="flex items-center space-x-4">
          <a
            href="https://twitter.com/flamingskullerc"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Twitter
          </a>
          <a
            href="https://t.me/BlueFlamingSkullerc"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Telegram
          </a>
        </div>
      </div>

      <h1 className="font-skull text-white z-[100] text-6xl  w-full animate-pulse mt-20">
        BLUE FLAMING SKULL
      </h1>
      <img className="spin z-[50]" src={blueSkullAnimated} />
      <h1 className="font-skull text-white z-[100] text-6xl w-full animate-pulse mb-10">
        ITS NOT JUST ANGER
      </h1>
      <img className="top-20 z-[5] absolute w-full" src={BlueSkullSmurfCat} />
      {skulls.map((skull) => (
        <img
          key={skull.id}
          src={blueSkull1}
          className={`${skull.animation} absolute z-[10]`}
          style={{ top: skull.top, left: skull.left }}
        />
      ))}
    </div>
  );
};

export default BlueFlame;
