import './App.css';
import BlueFlame from './BlueFlame';

function App() {
  return (
    <div className="App">
      <BlueFlame />
    </div>
  );
}

export default App;
